import { FileModel, CustomerPoModel, CustomerModel, UserModel } from '.';
import { unescape } from 'html-escaper';

/*
  status : Number
    0 = Pending
    4 = Completed
*/

export class CustomerInvoiceModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.code = data.code? unescape(data.code): null;
    
    this.customerPo = new CustomerPoModel(data.customerPo? data.customerPo: {});

    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.orderAt = data.orderAt? data.orderAt: null;
    this.payment = Number(data.payment? data.payment: 0);

    this.note = data.note? unescape(data.note): null;
    this.noteInternal = data.noteInternal? unescape(data.noteInternal): null;

    this.files = data.files && data.files.length
      ? data.files.map(d => new FileModel(d)): [];

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 4) return (<span className="ss-tag lg status-4">ดำเนินการแล้ว</span>);
      else return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }else{
      return (<span className="ss-tag lg">รอดำเนินการ</span>);
    }
  }
}
