import PropTypes from 'prop-types';
import { useState, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { appLogo, appName, appShortName, hex2rgb } from '../helpers/frontend';

import { connect } from 'react-redux';
import { userSignout, userPermission, userRead, userUpdate } from '../actions/user.actions';
import { processList as feProcessList } from '../actions/frontend.actions';
import { UserModel } from '../models';

function Header(props) {
	const user = new UserModel(props.user);

  const location = useLocation();
	const [currentPath, setCurrentPath] = useState('/');
  const [isReady, setIsReady] = useState(false);

  
  // START: Menu
	const [menu, setMenu] = useState([]);
	const [menuToggle, setMenuToggle] = useState([]);
  const onMenuToggle = (e, i) => {
    e.preventDefault();
    let temp = [...menuToggle];
    temp[i] = !temp[i];
    setMenuToggle(temp);
  };
  
	const [sidenavActive, setSidenavActive] = useState(false);
  const onSidenavActiveToggle = (e) => {
    e.preventDefault();
    setSidenavActive(!sidenavActive);
  };
  // END: Menu

  
	const onSignout = async (e) => {
    e.preventDefault();
		await props.processSignout();
    window.location.href = '/';
	};


  /* eslint-disable */
	useEffect(() => {
    props.feProcessList('currencies', { dataFilter: { status: 1 } });
  }, [props.user]);
	useEffect(() => {
    let temp = props.settings;
    if(Object.keys(temp).length){
      document.title = `${appName(temp['SYSTEM_NAME'])} Back Office`;
      if(temp['SYSTEM_COLOR_PRIMARY']){
        let _el = document.getElementById('system-style');
        if(_el){
          let rgb = hex2rgb(temp['SYSTEM_COLOR_PRIMARY']);
          let isLight = (rgb.r + rgb.g + rgb.b) / 3 > 200;
          _el.innerHTML = `
            :root{
              --p-color:${temp['SYSTEM_COLOR_PRIMARY']};
              --p-color-btn:${isLight? '#000000': '#ffffff'};
            }
          `;
        }
      }
      if(temp['SYSTEM_FAVICON']){
        let favicon = JSON.parse(temp['SYSTEM_FAVICON']);
        if(favicon.path){
          let favicon1 = document.getElementById('favicon-1');
          if(favicon1) favicon1.href = favicon.path;
          let favicon2 = document.getElementById('favicon-2');
          if(favicon2) favicon2.href = favicon.path;
        }
      }
      setIsReady(true);
    }
  }, [props.settings]);
	useEffect(() => {
    if(currentPath !== location.pathname){
      setCurrentPath(location.pathname);
      setSidenavActive(false);
      setMenuToggle(menu.map(() => false));
    }
  }, [location.pathname]);
	useEffect(() => {
    const onPreload = async () => {
      let temp = [];
      const u = new UserModel(props.user);
      if(u.isSignedIn()){
        if(u.isAdmin()){
          const isSuperAdmin = u.isSuperAdmin();
          temp = [
            {
              title: 'Admin',
              activeIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              children: [
                { title: 'Dashboard', to: '/admin', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Admins', to: '/admin/admins', activeIndex: 2, icon: 'fa-solid fa-user-lock' },
                { title: 'Account Managers', to: '/admin/account-managers', activeIndex: 3, icon: 'fa-solid fa-user-shield' },
                { title: 'Head of Sales', to: '/admin/head-of-sales', activeIndex: 4, icon: 'fa-solid fa-user-tag' },
                { title: 'Sales', to: '/admin/sales', activeIndex: 6, icon: 'fa-solid fa-user-clock' },
                { title: 'Employees', to: '/admin/employees', activeIndex: 7, icon: 'fa-solid fa-user-group' },
                isSuperAdmin? { title: 'Settings', to: '/admin/settings', activeIndex: 5, icon: 'fa-solid fa-gear' }: null,
              ]
            },
            {
              title: 'Product',
              activeIndexes: [21, 22, 23, 24, 25],
              children: [
                { title: 'Categories', to: '/admin/product-categories', activeIndex: 21, icon: 'fa-solid fa-tags' },
                { title: 'Products', to: '/admin/products', activeIndex: 22, icon: 'fa-solid fa-bag-shopping' },
              ]
            },
            {
              title: 'Supplier',
              activeIndexes: [26, 27, 28, 29, 30],
              children: [
                { title: 'Suppliers', to: '/admin/suppliers', activeIndex: 26, icon: 'fa-solid fa-building' },
                { title: 'Supplier PR', to: '/admin/supplier-prs', activeIndex: 27, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier PO', to: '/admin/supplier-pos', activeIndex: 28, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier RI', to: '/admin/supplier-invoices', activeIndex: 29, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier PP', to: '/admin/supplier-receipts', activeIndex: 30, icon: 'fa-solid fa-file-lines' },
              ]
            },
            {
              title: 'Stock',
              activeIndexes: [31, 32, 33, 34, 35],
              children: [
                { title: 'Report', to: '/admin/stock-reports', activeIndex: 33, icon: 'fa-solid fa-chart-simple' },
                { title: 'Stock Incomings', to: '/admin/stock-incomings', activeIndex: 31, icon: 'fa-solid fa-truck' },
                { title: 'Stocks', to: '/admin/stocks', activeIndex: 32, icon: 'fa-solid fa-warehouse' },
              ]
            },
            {
              title: 'Customer',
              activeIndexes: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
              children: [
                { title: 'Report', to: '/admin/customer-reports', activeIndex: 47, icon: 'fa-solid fa-chart-simple' },
                { title: 'Customers', to: '/admin/customers', activeIndex: 41, icon: 'fa-solid fa-users' },
                { title: 'Customer QT', to: '/admin/customer-prs', activeIndex: 42, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer IN', to: '/admin/customer-pos', activeIndex: 43, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer BN', to: '/admin/customer-invoices', activeIndex: 44, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer RE', to: '/admin/customer-receipts', activeIndex: 45, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer Order', to: '/admin/customer-orders', activeIndex: 46, icon: 'fa-solid fa-credit-card' },
              ]
            },
            {
              title: 'Finance',
              activeIndexes: [61, 62, 63, 64, 66, 66, 67, 68, 69, 70],
              children: [
                { title: 'Finance', to: '/admin/finances', activeIndex: 62, icon: 'fa-solid fa-coins' },
                { title: 'Employee Timesheet', to: '/admin/employee-timesheets', activeIndex: 63, icon: 'fa-solid fa-calendar' },
              ]
            },
          ];
        }else if(u.isAccountManager()){
          temp = [
            {
              title: 'Account Manager',
              activeIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              children: [
                { title: 'Dashboard', to: '/account-manager', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Head of Sales', to: '/account-manager/head-of-sales', activeIndex: 4, icon: 'fa-solid fa-user-tag' },
                { title: 'Sales', to: '/account-manager/sales', activeIndex: 6, icon: 'fa-solid fa-user-clock' },
                { title: 'Employees', to: '/account-manager/employees', activeIndex: 7, icon: 'fa-solid fa-user-group' },
              ]
            },
            {
              title: 'Product',
              activeIndexes: [21, 22, 23, 24, 25],
              children: [
                { title: 'Categories', to: '/account-manager/product-categories', activeIndex: 21, icon: 'fa-solid fa-tags' },
                { title: 'Products', to: '/account-manager/products', activeIndex: 22, icon: 'fa-solid fa-bag-shopping' },
              ]
            },
            {
              title: 'Supplier',
              activeIndexes: [26, 27, 28, 29, 30],
              children: [
                { title: 'Suppliers', to: '/account-manager/suppliers', activeIndex: 26, icon: 'fa-solid fa-building' },
                { title: 'Supplier PR', to: '/account-manager/supplier-prs', activeIndex: 27, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier PO', to: '/account-manager/supplier-pos', activeIndex: 28, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier RI', to: '/account-manager/supplier-invoices', activeIndex: 29, icon: 'fa-solid fa-file-lines' },
                { title: 'Supplier PP', to: '/account-manager/supplier-receipts', activeIndex: 30, icon: 'fa-solid fa-file-lines' },
              ]
            },
            {
              title: 'Stock',
              activeIndexes: [31, 32, 33, 34, 35],
              children: [
                { title: 'Report', to: '/account-manager/stock-reports', activeIndex: 33, icon: 'fa-solid fa-chart-simple' },
                { title: 'Stock Incomings', to: '/account-manager/stock-incomings', activeIndex: 31, icon: 'fa-solid fa-truck' },
                { title: 'Stocks', to: '/account-manager/stocks', activeIndex: 32, icon: 'fa-solid fa-warehouse' },
              ]
            },
            {
              title: 'Customer',
              activeIndexes: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
              children: [
                { title: 'Report', to: '/account-manager/customer-reports', activeIndex: 47, icon: 'fa-solid fa-chart-simple' },
                { title: 'Customers', to: '/account-manager/customers', activeIndex: 41, icon: 'fa-solid fa-users' },
                { title: 'Customer QT', to: '/account-manager/customer-prs', activeIndex: 42, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer IN', to: '/account-manager/customer-pos', activeIndex: 43, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer BN', to: '/account-manager/customer-invoices', activeIndex: 44, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer RE', to: '/account-manager/customer-receipts', activeIndex: 45, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer Order', to: '/account-manager/customer-orders', activeIndex: 46, icon: 'fa-solid fa-credit-card' },
              ]
            },
            {
              title: 'Finance',
              activeIndexes: [61, 62, 63, 64, 66, 66, 67, 68, 69, 70],
              children: [
                { title: 'Finance', to: '/account-manager/finances', activeIndex: 62, icon: 'fa-solid fa-coins' },
                { title: 'Employee Timesheet', to: '/account-manager/employee-timesheets', activeIndex: 63, icon: 'fa-solid fa-calendar' },
              ]
            },
          ];
        }else if(u.isHeadOfSales()){
          temp = [
            {
              title: 'Head of Sales',
              activeIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              children: [
                { title: 'Dashboard', to: '/head-of-sales', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Sales', to: '/head-of-sales/sales', activeIndex: 6, icon: 'fa-solid fa-user-clock' },
              ]
            },
            {
              title: 'Product',
              activeIndexes: [21, 22, 23, 24, 25],
              children: [
                { title: 'Categories', to: '/head-of-sales/product-categories', activeIndex: 21, icon: 'fa-solid fa-tags' },
                { title: 'Products', to: '/head-of-sales/products', activeIndex: 22, icon: 'fa-solid fa-bag-shopping' },
              ]
            },
            {
              title: 'Supplier',
              activeIndexes: [26, 27, 28, 29, 30],
              children: [
                { title: 'Suppliers', to: '/head-of-sales/suppliers', activeIndex: 26, icon: 'fa-solid fa-building' },
              ]
            },
            {
              title: 'Stock',
              activeIndexes: [31, 32, 33, 34, 35],
              children: [
                { title: 'Stocks', to: '/head-of-sales/stocks', activeIndex: 32, icon: 'fa-solid fa-warehouse' },
              ]
            },
            {
              title: 'Customer',
              activeIndexes: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
              children: [
                { title: 'Report', to: '/head-of-sales/customer-reports', activeIndex: 47, icon: 'fa-solid fa-chart-simple' },
                { title: 'Customers', to: '/head-of-sales/customers', activeIndex: 41, icon: 'fa-solid fa-users' },
                { title: 'Customer QT', to: '/head-of-sales/customer-prs', activeIndex: 42, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer IN', to: '/head-of-sales/customer-pos', activeIndex: 43, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer BN', to: '/head-of-sales/customer-invoices', activeIndex: 44, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer RE', to: '/head-of-sales/customer-receipts', activeIndex: 45, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer Order', to: '/head-of-sales/customer-orders', activeIndex: 46, icon: 'fa-solid fa-credit-card' },
              ]
            },
            {
              title: 'Finance',
              activeIndexes: [61, 62, 63, 64, 66, 66, 67, 68, 69, 70],
              children: [
                { title: 'Finance', to: '/head-of-sales/finances', activeIndex: 62, icon: 'fa-solid fa-coins' },
                { title: 'Employee Timesheet', to: '/head-of-sales/employee-timesheets', activeIndex: 63, icon: 'fa-solid fa-calendar' },
              ]
            },
          ];
      
        }else if(u.isSales()){
          temp = [
            {
              title: 'Sales',
              activeIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              children: [
                { title: 'Dashboard', to: '/sales', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
              ]
            },
            {
              title: 'Product',
              activeIndexes: [21, 22, 23, 24, 25],
              children: [
                { title: 'Categories', to: '/sales/product-categories', activeIndex: 21, icon: 'fa-solid fa-tags' },
                { title: 'Products', to: '/sales/products', activeIndex: 22, icon: 'fa-solid fa-bag-shopping' },
              ]
            },
            {
              title: 'Stock',
              activeIndexes: [31, 32, 33, 34, 35],
              children: [
                { title: 'Stocks', to: '/sales/stocks', activeIndex: 32, icon: 'fa-solid fa-warehouse' },
              ]
            },
            {
              title: 'Customer',
              activeIndexes: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
              children: [
                { title: 'Report', to: '/sales/customer-reports', activeIndex: 47, icon: 'fa-solid fa-chart-simple' },
                { title: 'Customers', to: '/sales/customers', activeIndex: 41, icon: 'fa-solid fa-users' },
                { title: 'Customer QT', to: '/sales/customer-prs', activeIndex: 42, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer IN', to: '/sales/customer-pos', activeIndex: 43, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer BN', to: '/sales/customer-invoices', activeIndex: 44, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer RE', to: '/sales/customer-receipts', activeIndex: 45, icon: 'fa-solid fa-file-lines' },
                { title: 'Customer Order', to: '/sales/customer-orders', activeIndex: 46, icon: 'fa-solid fa-credit-card' },
              ]
            },
            {
              title: 'Finance',
              activeIndexes: [61, 62, 63, 64, 66, 66, 67, 68, 69, 70],
              children: [
                { title: 'Finance', to: '/sales/finances', activeIndex: 62, icon: 'fa-solid fa-coins' },
                { title: 'Employee Timesheet', to: '/sales/employee-timesheets', activeIndex: 63, icon: 'fa-solid fa-calendar' },
              ]
            },
          ];
        }
      }
      setMenu(temp);
      setMenuToggle(temp.map(() => false));
    };
    onPreload();
  }, [props.user, props.settings]);
  /* eslint-enable */

  return !isReady? (<></>): !user.isSignedIn()? (<></>): (
    ( currentPath.indexOf('/admin') > -1 && user.isAdmin() )
    || ( currentPath.indexOf('/account-manager') === 0 && user.isAccountManager() )  
    || ( currentPath.indexOf('/head-of-sales') === 0 && user.isHeadOfSales() )  
    || ( currentPath.indexOf('/sales') > -1 && user.isSales() ) ? (<>
      {/* Topnav */}
      <nav className="topnav bcolor-fgray">
        <div className="wrapper">
          <Link to={user.path()} className="logo-container h-color-p">
            <img className="logo" alt="Logo" 
              src={appLogo(props.settings[`SYSTEM_LOGO`])} 
            />
            <div className="text fw-900">
              {appShortName(props.settings[`SYSTEM_SHORT_NAME`])}
            </div>
          </Link>
          <div className="sidenav-toggle" onClick={onSidenavActiveToggle}>
            <div className={`hamburger ${sidenavActive? 'active': ''}`}>
              <div></div><div></div><div></div>
            </div>
          </div>

          <div className="options">

            {/* Profile */}
            <div className="option">
              <div className="option-icon">
                <div className="avatar xs">
                  <div className="avatar-bg" style={{ backgroundImage: `url('${user.avatar.path}')` }}></div>
                </div>
              </div>
              <div className="dropdown bcolor-fgray">
                <div className="wrapper bcolor-fgray">
                  <h6 className="name">{user.displayName()}</h6>
                  <p className="role color-gray">ตำแหน่ง : {user.displayRole()}</p>
                </div>
                <div className="wrapper bcolor-fgray">
                  <Link to={`${user.path()}/profile`} className="dropdown-item">
                    <div className="icon"><em className="fa-solid fa-user"></em></div>
                    ข้อมูลส่วนตัว
                  </Link>
                  <div className="dropdown-item c-pointer" onClick={onSignout}>
                    <div className="icon"><em className="fa-solid fa-right-to-bracket"></em></div>
                    ออกจากระบบ
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </nav>

      {/* Sidenav */}
      <nav className={`sidenav ${sidenavActive? 'active': ''}`}>
        <ul className="wrapper">
          {menu.map((d, i) => (
            <Fragment key={`menu_${i}`}>
              {d && (!d.children || d.children.length)? (
                <div className={`menu-set ${d.activeIndexes.indexOf(props.sidenavActiveIndex)>-1 || menuToggle[i]? 'active': ''}`}>
                  {d.to? (
                    <Link className="menu-header" to={d.to}>
                      {d.title}
                    </Link>
                  ): (
                    <div className="menu-header" onClick={e => onMenuToggle(e, i)}>
                      {d.title}
                      {d.children && d.children.length? (
                        <div className="chev"><em className="fa-solid fa-chevron-right"></em></div>
                      ): (<></>)}
                    </div>
                  )}
                  {d.children && d.children.length? (
                    <div className="menu-container">
                      {d.children.map((k, j) => (
                        <Fragment key={`submenu_${i}_${j}`}>
                          {k? (
                            <Link to={k.to} className={`menu ${props.sidenavActiveIndex===k.activeIndex? 'active': ''}`}>
                              <div className="icon"><em className={k.icon}></em></div>
                              <span className="label">{k.title}</span>
                            </Link>
                          ): (<></>)}
                        </Fragment>
                        ))}
                    </div>
                  ): (<></>)}
                </div>
              ): (<></>)}
            </Fragment>
          ))}
        </ul>
      </nav>
    </>): (<></>)
  );
}

Header.defaultProps = {
  
};
Header.propTypes = {
  processSignout: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
  userPermission: PropTypes.func.isRequired,
  userRead: PropTypes.func.isRequired,
  userUpdate: PropTypes.func.isRequired,
  feProcessList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.app.settings,
	user: state.user.user,
  sidenavActiveIndex: state.app.sidenavActiveIndex,
});

export default connect(mapStateToProps, {
  processSignout: userSignout,
  userPermission: userPermission,
  userRead: userRead,
  userUpdate: userUpdate,
  feProcessList: feProcessList,
})(Header);